<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st7" d="M0.094,30.226c6.099-0.015,12.198-0.013,18.297-0.065c0.943-0.008,1.533,0.312,2.109,1.065   c7.022,9.173,14.076,18.319,21.126,27.469c0.203,0.264,0.444,0.498,0.932,1.041c0-10.012,0-19.674,0-29.462   c3.838,0,7.478,0,11.247,0c0,13.087,0,26.145,0,39.367c-0.524,0.029-1.05,0.083-1.576,0.084c-5.505,0.002-11.01,0.03-16.513-0.056   c-0.65-0.01-1.491-0.498-1.906-1.034c-7.141-9.207-14.229-18.457-21.33-27.696c-0.296-0.386-0.609-0.758-1.2-1.493   c0,10.307,0,20.211,0,30.348c-3.879,0-7.532,0-11.185,0C0.094,56.604,0.094,43.415,0.094,30.226z"/>
            <path class="st7" d="M238.184,69.793c-4.113-0.032-8.225-0.096-12.337-0.072c-0.912,0.005-1.452-0.242-1.853-1.087   c-0.794-1.674-1.623-3.338-2.562-4.931c-0.26-0.442-0.957-0.874-1.457-0.877c-7.038-0.05-14.077-0.009-21.115-0.038   c-0.868-0.004-1.361,0.274-1.736,1.057c-0.8,1.672-1.639,3.328-2.545,4.943c-0.225,0.402-0.761,0.879-1.162,0.885   c-4.003,0.065-8.008,0.04-12.264,0.04c0.782-1.504,1.452-2.823,2.15-4.127c6.072-11.354,12.16-22.699,18.207-34.066   c0.533-1.002,1.128-1.431,2.299-1.403c3.914,0.092,7.831,0.102,11.745,0.031c1.165-0.021,1.752,0.388,2.292,1.404   c6.45,12.114,12.943,24.203,19.435,36.294c0.254,0.473,0.6,0.896,0.902,1.342C238.184,69.391,238.184,69.592,238.184,69.793z    M201.813,54.231c5.133,0,9.982,0,15.09,0c-2.543-4.906-4.968-9.584-7.549-14.562C206.768,44.662,204.329,49.373,201.813,54.231z"/>
            <path class="st7" d="M83.475,70.04c-4.511,0-9.022,0.022-13.533-0.017c-1.033-0.009-2.081-0.15-3.094-0.364   c-5.044-1.065-7.878-4.181-8.013-9.413c-0.181-6.989-0.175-13.99,0.005-20.979c0.127-4.917,2.862-8.172,7.632-9.04   c3.144-0.572,6.402-0.63,9.612-0.661c7.04-0.067,14.083-0.053,21.121,0.11c1.994,0.046,4.067,0.462,5.941,1.158   c3.444,1.279,5.393,4.074,5.481,7.73c0.181,7.49,0.161,14.989,0.028,22.481c-0.073,4.108-2.825,7.491-6.807,8.282   c-2.99,0.594-6.093,0.639-9.151,0.837c-1.53,0.099-3.073-0.012-4.61-0.019c-1.537-0.007-3.074-0.001-4.611-0.001   C83.475,70.11,83.475,70.075,83.475,70.04z M83.722,39.467c0-0.011,0-0.022,0-0.033c-3.668,0-7.337-0.058-11.003,0.02   c-2.508,0.053-3.277,0.854-3.309,3.422c-0.058,4.628-0.056,9.257,0.004,13.885c0.034,2.671,0.776,3.434,3.372,3.523   c2.574,0.088,5.153,0.047,7.729,0.038c4.857-0.017,9.714-0.009,14.57-0.099c2.255-0.042,2.986-0.739,3.025-3.008   c0.083-4.875,0.105-9.753-0.001-14.627c-0.054-2.455-0.767-3.061-3.237-3.104C91.156,39.419,87.439,39.467,83.722,39.467z"/>
            <path class="st7" d="M161.129,30.513c-6.88,5.982-13.76,11.963-20.856,18.134c7.953,6.987,15.786,13.87,23.62,20.753   c-0.032,0.109-0.064,0.218-0.096,0.327c-2.004,0-4.008,0-6.012,0c-3.025,0-6.051,0.032-9.074-0.028   c-0.583-0.011-1.303-0.222-1.716-0.609c-6.912-6.49-13.785-13.023-20.663-19.549c-0.137-0.13-0.238-0.301-0.424-0.539   c2.451-2.322,4.861-4.628,7.294-6.907c3.897-3.651,7.784-7.313,11.74-10.898c0.598-0.542,1.536-0.963,2.326-0.979   c4.591-0.091,9.184-0.045,13.776-0.045C161.071,30.286,161.1,30.4,161.129,30.513z"/>
            <path class="st7" d="M178.548,69.647c-3.912,0-7.708,0-11.631,0c0-13.103,0-26.182,0-39.378c3.86,0,7.689,0,11.631,0   C178.548,43.371,178.548,56.419,178.548,69.647z"/>
            <path class="st7" d="M113.809,69.716c0-13.245,0-26.256,0-39.355c0.361-0.074,0.684-0.196,1.008-0.197   c3.171-0.013,6.343,0.015,9.514-0.018c0.937-0.01,1.293,0.279,1.291,1.294c-0.03,12.32-0.02,24.64-0.013,36.96   c0,0.692,0.041,1.327-0.935,1.322C121.114,69.707,117.556,69.716,113.809,69.716z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'nokia-brand',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>
